<template>
  <base-section id="we-help-your-success">
    <div class="text-center">
      <v-container>
        <base-img
          :src="require('@/assets/FAVICON-EK-candy.svg')"
          alt="KidiwiServices"
          class="mx-auto"
          max-width="100"
        />
      </v-container>
    </div>
    <base-section-heading :title="$t('weHelpYourSuccess.mainTitle')" outlined>
      {{ $t("weHelpYourSuccess.line1") }}
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col v-for="n in 4" :key="n" cols="6" md="3">
          <v-hover>
            <template #default="{ hover }">
              <base-img
                :height="$vuetify.breakpoint.mdAndUp ? 400 : 200"
                :src="require(`@/assets/team-${n}.jpg`)"
                color="grey lighten-1"
                flat
                tile
              >
                <v-overlay
                  :value="hover"
                  absolute
                  class="pa-6"
                  color="secondary"
                  opacity=".9"
                >
                  <!--
                  <base-subheading :title="`Name ${n}`" />
                  -->
                  <base-subheading :title="$t(features[n - 1].title)" />

                  <base-body>
                    {{ $t(features[n - 1].text) }}
                  </base-body>
                </v-overlay>
              </base-img>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
export default {
  name: "SectionWeHelpYourSuccess",
  data: () => ({
    features: [
      {
        title: "weHelpYourSuccess.title1",
        text: "weHelpYourSuccess.text1",
      },
      {
        title: "weHelpYourSuccess.title2",
        text: "weHelpYourSuccess.text2",
      },
      {
        title: "weHelpYourSuccess.title3",
        text: "weHelpYourSuccess.text3",
      },
      {
        title: "weHelpYourSuccess.title4",
        text: "weHelpYourSuccess.text4",
      },
    ],
  }),
};
</script>
